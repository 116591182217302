.bouncing-dot {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .bouncing-dot div {
    
    border-radius: 50%;
    display: inline-block;
    margin: 0px 2px 0px 2px;
    animation: bouncing 0.6s infinite;
  }
  
  .bouncing-dot div:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .bouncing-dot div:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes bouncing {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  